/* eslint-disable global-require */
<template>
  <div>
    <v-card flat-not>
      <v-card-text>
        <p class="text-2xl font-weight-semibold text--primary mb-2">
          {{ $t('RegisterWizard.body.tab1.title') }}
        </p>
        <p class="mb-2">
          {{ $t('RegisterWizard.body.tab1.subtitle') }}
        </p>
      </v-card-text>

      <!-- Form Personal Info -->
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="validationForm"
        >
          <v-row>
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.username"
                outlined
                :label="$t('RegisterWizard.body.tab1.form.username')"
                :placeholder="$t('RegisterWizard.body.tab1.form.username_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.username"
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('RegisterWizard.body.tab1.form.password')"
                :placeholder="$t('RegisterWizard.body.tab1.form.password_placeholder')"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details="auto"
                :error-messages="errorMessages.password"
                :rules="[required]"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.firstName"
                outlined
                :label="$t('RegisterWizard.body.tab1.form.first_name')"
                :placeholder="$t('RegisterWizard.body.tab1.form.first_name_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.firstName"
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.lastName"
                outlined
                :label="$t('RegisterWizard.body.tab1.form.last_name')"
                :placeholder="$t('RegisterWizard.body.tab1.form.last_name_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.lastName"
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <!-- Languages -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-autocomplete
                v-model="localOptions.language"
                :items="languageOptions"
                filled
                outlined
                hide-details="auto"
                :label="$t('RegisterWizard.body.tab1.form.language')"
                item-text="text"
                item-value="value"
                :menu-props="{contentClass:'list-style'}"
                :rules="[required]"
              >
                <!-- close @click:close="removeLanguage(data.item)" -->
                <template #selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click="data.select"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.img"></v-img>
                    </v-avatar>
                    {{ data.item.text }}
                  </v-chip>
                </template>

                <template #item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>

                  <template v-else>
                    <v-list-item-avatar>
                      <v-img :src="data.item.img"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                      <!-- <v-list-item-subtitle>
                        {{ data.item.text }}
                      </v-list-item-subtitle> -->
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-checkbox
                v-model="localOptions.agree"
                hide-details="auto"
                class="mt-0"
                :rules="[requiredcheckbox]"
              >
                <template #label>
                  <div class="d-flex align-center flex-wrap">
                    <!-- <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a> -->
                    <span class="me-2">{{ $t('RegisterWizard.body.tab1.form.agree_term1') }}</span>
                    <router-link :to="{}">
                      {{ $t('RegisterWizard.body.tab1.form.agree_term2') }}
                    </router-link>
                  </div>
                  <small class="text-danger">{{ errorMessages.agree }}</small>
                </template>
              </v-checkbox>
            </v-col>
            <!--/Agree  -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import {
  required,
  requiredcheckbox,
  emailValidator,
  passwordValidator,
} from '@core/utils/validation'

// Components
// import { useRouter } from '@core/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
  },
  props: {
    personalData: {
      type: Object,
      default: () => ({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        language: '', // i18n.locale,
        agree: false,
      }),
    },
  },
  setup(props, { emit }) {
    const { useI18n } = useI18nUtils()
    const { i18n } = useI18n()

    // Data
    const languageOptions = [
      // { value: 'nothing_selected', text: 'Nothing Selected' },
      { value: 'es', text: 'Spanish', img: require('@/assets/images/flags/es.png') },
      // eslint-disable-next-line global-require
      { value: 'en', text: 'English', img: require('@/assets/images/flags/en.png') },
      // eslint-disable-next-line global-require
      { value: 'fr', text: 'French', img: require('@/assets/images/flags/fr.png') },
      // eslint-disable-next-line global-require
      { value: 'de', text: 'German', img: require('@/assets/images/flags/de.png') },
      // eslint-disable-next-line global-require
      { value: 'pt', text: 'Portuguese', img: require('@/assets/images/flags/pt.png') },
    ]

    // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
    // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
    const localOptions = ref(JSON.parse(JSON.stringify(props.personalData)))
    const isPasswordVisible = ref(false)
    const errorMessages = ref([])

    // // watch(props.chatUserProfile, () => {
    watch(() => localOptions.value.language, newValueLanguage => {
      i18n.locale = newValueLanguage
      emit('change-language', newValueLanguage) // Forze reRender
    })

    const removeLanguage = () => {
      localOptions.value.language = ''
    }

    // Form Ref
    const form = ref(null)
    const validationForm = () => new Promise((resolve, reject) => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return reject()

      // ok
      emit('input', localOptions.value)

      return resolve(true)
    })

    return {
      // Form validations
      required,
      requiredcheckbox,
      emailValidator,
      passwordValidator,
      form,
      validationForm, // Available from Wizard

      // Data
      languageOptions,
      localOptions,
      isPasswordVisible,
      errorMessages,

      // Events
      removeLanguage,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  @import '@core/preset/preset/pages/auth.scss';
</style>
