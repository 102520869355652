/* eslint-disable global-require */
<template>
  <div>
    <!-- <tab-content
      ref="accountRules"
      title="Address"
      :before-change="validationFormCompanyAddress"
    > -->
    <v-card flat-not>
      <v-card-text>
        <p
          class="text-2xl font-weight-semibold text--primary mb-2"
        >
          {{ $t('RegisterWizard.body.tab2.title') }}
        </p>
        <p class="mb-2">
          {{ $t('RegisterWizard.body.tab2.subtitle') }}
        </p>
      </v-card-text>

      <!-- Form Company Info -->
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="validationForm"
        >
          <v-row>
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.name"
                outlined
                :label="$t('RegisterWizard.body.tab2.form.name')"
                :placeholder="$t('RegisterWizard.body.tab2.form.name_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.name"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.phone"
                outlined
                :label="$t('RegisterWizard.body.tab2.form.phone')"
                :placeholder="$t('RegisterWizard.body.tab2.form.phone_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.phone"
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <CountrySelect
                v-if="forceReRender"
                id="company-country"
                v-model="localOptions.country"
                :country-data="localOptions.country"
                :placeholder="$t('RegisterWizard.body.tab2.form.country_placeholder')"
                :error-messages="errorMessages.country"
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <RegionSelect
                id="address-region"
                v-model="localOptions.region"
                :country-data="localOptions.country"
                :region-data="localOptions.region"
                :placeholder="$t('RegisterWizard.body.tab2.form.region_placeholder')"
                :error-messages="errorMessages.region"
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.city"
                outlined
                :label="$t('RegisterWizard.body.tab2.form.city')"
                :placeholder="$t('RegisterWizard.body.tab2.form.city_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.city"
                :rules="[]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="localOptions.zip"
                outlined
                :label="$t('RegisterWizard.body.tab2.form.zip')"
                :placeholder="$t('RegisterWizard.body.tab2.form.zip_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.zip"
                :rules="[]"
              ></v-text-field>
            </v-col>
            <v-col sm="12">
              <v-text-field
                v-model="localOptions.street"
                outlined
                :label="$t('RegisterWizard.body.tab2.form.street')"
                :placeholder="$t('RegisterWizard.body.tab2.form.street_placeholder')"
                hide-details="auto"
                :error-messages="errorMessages.street"
                :rules="[]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, nextTick } from '@vue/composition-api'

import { required } from '@core/utils/validation'

// Components
// import { useRouter } from '@core/utils'
// import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CountrySelect from '@/components/CountrySelect.vue'
import RegionSelect from '@/components/RegionSelect.vue'

export default {
  components: {
    CountrySelect,
    RegionSelect,
  },
  props: {
    companyData: {
      type: Object,
      default: () => ({
        name: '',
        phone: '',
        country: '',
        region: '',
        city: '',
        zip: '',
        street: '',
      }),
    },
  },
  setup(props, { emit }) {
    // Data
    const localOptions = ref(JSON.parse(JSON.stringify(props.companyData)))
    const errorMessages = ref([])

    // Form Ref
    const form = ref(null)
    const validationForm = () => new Promise((resolve, reject) => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return reject()

      // ok
      emit('input', localOptions.value)

      return resolve(true)
    })

    const forceReRender = ref(true)

    // The parent inform that the language was changed:
    // SelecCountry is a manual translate component. We rerender for tranlate
    const onChangeLanguage = () => {
      // debugger

      // Best way to forze re-render: https://michaelnthiessen.com/force-re-render/
      forceReRender.value = false
      nextTick(() => {
        forceReRender.value = true
      })
    }

    return {
      // Form validations
      required,
      form,
      validationForm, // Available from Wizard

      // Data
      localOptions,
      errorMessages,

      // forze re-render
      forceReRender,
      onChangeLanguage,
    }
  },
}
</script>
