<template>
  <div class="auth-wrapper-kk auth-v1-kk pa-0">
    <div class="auth-inner-kk">
      <v-row>
        <v-col
          cols="12"
          lg="12"
          class="mx-auto"
        >
          <v-card
            flat
            class="rounded-0"
          >
            <v-card-text>
              <div class="d-flex align-center my-2">
                <p class="text-2xl font-weight-semibold text--primary ">
                  <!-- Adventure starts here 🚀 -->
                  {{ $t('RegisterWizard.header.title') }}
                </p>
                <p class="pl-2">
                  <!-- Make your app management easy and fun! -->
                  ({{ $t('RegisterWizard.header.subtitle') }})
                </p>
              </div>

              <p>{{ $t('RegisterWizard.header.text') }}</p>
              <p>{{ $t('RegisterWizard.header.text_p2') }}</p>
              <p>{{ $t('RegisterWizard.header.text_p3') }}</p>
            </v-card-text>

            <!-- login form -->
            <!-- <v-card-text> -->
            <!-- Wizard-->
            <form-wizard
              ref="refFormWizard"
              :color="appColorAccent"
              :title="null"
              :subtitle="null"
              layout="horizontal"
              shape="circle"
              :back-button-text="$t('RegisterWizard.footer.back')"
              :next-button-text="$t('RegisterWizard.footer.next')"
              :finish-button-text="$t('RegisterWizard.footer.finish')"
              class="vertical-steps-kk steps-transparent mb-3"
              @on-complete="handleFormSubmit"
            >
              <!-- Tab1: Personal data -->
              <!-- :before-change="validationFormPersonal" -->
              <!-- icon="feather icon-user" -->
              <tab-content
                :title="$t('RegisterWizard.body.tab1.menu')"
                :before-change="validationFormTab1"
              >
                <!-- ref="tab1" -->
                <!-- v-if="options.personalData" -->
                <RegisterWizardFormStep1Personal
                  ref="refFormWizardTab1"
                  v-model="options.personalData"
                  :personal-data="options.personalData"
                  @change-language="onChangeLanguage"
                  @next-step="formWizardNextStep"
                />
              </tab-content>

              <!-- Tab2: Company data (ShieldIcon) -->
              <!-- :before-change="validationFormCompany" -->
              <!-- icon="feather icon-shield" -->
              <tab-content
                :title="$t('RegisterWizard.body.tab2.menu')"
                :before-change="validationFormTab2"
              >
                <RegisterWizardFormStep2Company
                  ref="refFormWizardTab2"
                  v-model="options.companyData"
                  :company-data="options.companyData"
                />
              </tab-content>

              <!-- Tab3: Plan data  -->
              <!-- :before-change="validationFormPlan" -->
              <!-- icon="feather icon-credit-card" -->
              <tab-content
                :title="$t('RegisterWizard.body.tab3.menu')"
                :before-change="validationFormTab3"
              >
                <RegisterWizardFormStep3Plan
                  v-if="options.planData"
                  ref="refFormWizardTab3"
                  v-model="options.planData"
                  :plan-data="options.planData"
                />
              </tab-content>
            </form-wizard>
            <!-- </v-card-text> -->

            <!-- create new account  -->
            <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
              <p class="mb-0 me-2">
                Already have an account?
              </p>
              <router-link :to="{name:'auth-login'}">
                Sign in instead
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// Components
import { useRouter } from '@core/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Components form
import RegisterWizardFormStep1Personal from './RegisterWizardFormStep1Personal.vue'
import RegisterWizardFormStep2Company from './RegisterWizardFormStep2Company.vue'
import RegisterWizardFormStep3Plan from './RegisterWizardFormStep3Plan.vue'

// Services
import useAuthHandler from '@/views/pages/auth/useAuthHandler'
import useJwt from '@/services/api/modules/auth/useJwt'

export default {
  components: {
    FormWizard,
    TabContent,
    RegisterWizardFormStep1Personal,
    RegisterWizardFormStep2Company,
    RegisterWizardFormStep3Plan,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const { sendAuthNotification } = useAuthHandler()

    const { useI18n } = useI18nUtils()
    const { i18n } = useI18n()
    const { route, router } = useRouter()

    // Query params: Leer plan y proponer
    const planCode = route.value.query.plan_code || 'purchaser-big-market'

    // Initial purpose - blank
    const formOptions = {
      personalData: {
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        language: i18n.locale,
        agree: false,
      },
      companyData: {
        name: '',
        phone: '',
        country: '',
        region: '',
        city: '',
        zip: '',
        street: '',
      },
      planData: {
        profile_code: 'purchaser',
        plan_code: planCode, // 'purchaser-big-market',

        // plan_price_montly: '90',
        plan_annual: true,
      },
    }

    // Data
    const formSent = ref(false)
    const refreshToken = ref('')
    const options = ref(formOptions)

    // Form Wizard
    const refFormWizard = ref(null)
    const formWizardNextStep = () => refFormWizard.value.nextTab()

    // // Tab1 ref (Access to validate form)
    const refFormWizardTab1 = ref(null)
    const validationFormTab1 = () => refFormWizardTab1.value.validationForm() // Must exist on Tab1
    const refFormWizardTab2 = ref(null)
    const validationFormTab2 = () => refFormWizardTab2.value.validationForm() // Must exist on Tab2
    const refFormWizardTab3 = ref(null)
    const validationFormTab3 = () => refFormWizardTab3.value.validationForm() // Must exist on Tab3

    // Validations FORM using $refs: Usamos así pq. en el template no encuentra referencia a tab*
    const validationFormPersonal = () => refFormWizard.value.$refs.tab1.validationFormPersonal()

    //   return this.$refs.tab1.validationFormPersonal()
    //     .then(response => {
    //       console.log(response)
    //       this.$i18n.locale = 'en'
    //       this.$refs.tab2.validationFormCompany()
    //       return Promise.resolve(response)
    //     })
    //   this.$i18n.locale = 'en'
    //   return Promise.resolve(true)
    const validationFormCompany = () => refFormWizard.value.$refs.tab2.validationFormCompany()
    const validationFormPlan = () => refFormWizard.value.$refs.tab3.validationFormPlan()

    // Submit
    const formSubmitted = () => {
      const args = {
        personalData: options.value.personalData,
        companyData: options.value.companyData,
        planData: options.value.planData,
      }

      useJwt.registerByWizard(args)
        .then(response => {
          // TEST: Renderizamos el token para simular la validacion por correo
          const { accessToken } = response.data
          refreshToken.value = accessToken

          // Inform user and block re-send
          formSent.value = true
          sendAuthNotification('Registration complete', 'success', 'EditIcon')

          // >>> login actions in component
          useJwt.setRememberMe(false, null, null) // don't remember
          router.push({ name: 'auth-login' }) // router.push('/')
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // TODO - el envio de errores falla
            // this.$refs.refTab1.$refs.refFormObserverStepContent.setErrors(error.response.data.error)
            // refFormWizard.value.$refs.refFormObserverStepContent.setErrors(error.response.data.error)

            // TODO - Si tiene error en xxx: [Error] -> Pasamos al 1, sino al 0
            //    Tab 0 Contenido
            //    Tab 1
            refFormWizard.value.changeTab(1, 0) // Tab1 >>> Tab0

            sendAuthNotification(error.response.data.message, 'error')
          }
        })
    }

    const handleFormSubmit = () => {
      formSubmitted()

      //   validationFormTab1()
      //     .then(() => {
      //       validationFormTab2()
      //         .then(() => {
      //           formSubmitted()
      //         })
      //     })
    }

    // El idioma de comunicacion ha sido cambiado en el step1
    const onChangeLanguage = event => {
      // Forze rendered
      // refFormWizardTab1.value.onChangeLanguage(event) // Este ya se ha actualizado solo
      refFormWizardTab2.value.onChangeLanguage(event)
      refFormWizardTab3.value.onChangeLanguage(event)
    }

    return {
      // Data
      formSent,
      refreshToken,
      options,

      // Form
      refFormWizard,
      refFormWizardTab1,
      refFormWizardTab2,
      refFormWizardTab3,
      formWizardNextStep,
      validationFormTab1,
      validationFormTab2,
      validationFormTab3,
      validationFormPersonal,
      validationFormCompany,
      validationFormPlan,
      handleFormSubmit,
      onChangeLanguage,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appColorPrimary: themeConfig.themes.light.primary,
      appColorAccent: themeConfig.themes.light.accent,

      icons: {
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
// @import '@core/preset/preset/pages/auth.scss';
  .vue-form-wizard .wizard-tab-content {
    padding: 0;
  }
</style>
