<template>
  <div class="mx-4-not">
    <v-card flat-not>
      <!-- Header title/subtitle -->
      <v-card-text>
        <p
          class="text-2xl font-weight-semibold text--primary mb-2"
        >
          {{ $t('RegisterWizard.body.tab3.title') }}
        </p>
        <div
          class="d-flex align-items-center justify-space-between flex-wrap"
        >
          <p class="mb-2">
            {{ $t('RegisterWizard.body.tab3.subtitle') }}
          </p>
          <router-link
            :to="{name:'pricing'}"
            target="_blank"
          >
            {{ $t('RegisterWizard.body.tab3.pricing_link') }}
            <v-icon> {{ icons.mdiOpenInNew }} </v-icon>
          </router-link>
        </div>
      </v-card-text>
      <!-- Body: Profile + Plan -->
      <v-card-text>
        <!-- title text and switch button -->
        <!--plan switch -->
        <div class="d-flex align-center justify-center mt-1 mb-1 pb-50">
          <span class="font-weight-semibold">Monthly</span>
          <v-switch
            v-model="annualPeriodShow"
            class="mx-3"
          ></v-switch>
          <span class="font-weight-semibold">Annually</span>
        </div>
        <!--/ Montly vs annual  -->

        <!-- Plan -->
        <v-row>
          <v-col
            v-for="(plan, index) in planList"
            :key="index"
            class="d-flex child-flex"
            cols="12"
            md="3"
            sm="6"
          >
            <div
              class="text-left custom-options-checkable"
            >
              <div class="">
                <input
                  :id="plan.id"
                  v-model="planSelected"
                  class="custom-option-item-check"
                  type="radio"
                  :value="plan.id"
                  @change="onChangePlan"
                >
                <label
                  :for="plan.id"
                  class="d-flex flex-column align-items-left px-2 py-2 custom-option-item"
                >
                  <!-- chip -->
                  <div
                    v-show="plan.popular"
                    style="position: absolute; right: 10px;"
                    class="pricing-badge text-right"
                  >
                    <v-chip
                      small
                      color="primary"
                      class="v-chip-light-bg primary--text font-weight-semibold"
                    >
                      Popular
                    </v-chip>
                  </div>

                  <v-icon
                    size="28"
                    class="me-2 mt-1"
                  >
                    {{ plan.icon }}
                  </v-icon>

                  <span class="pt-1 pl-1">
                    <h3>{{ plan.title }}</h3>
                    <span class="d-block mt-75">
                      {{ plan.subtitle }}
                    </span>
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                        <span class="pricing-value font-weight-bolder text-primary">{{ annualPeriodShow ? plan.yearlyPlan.perMonth : plan.monthlyPrice }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                      </div>
                      <small
                        v-show="annualPeriodShow"
                        class="annual-pricing text-muted"
                      >EUR {{ plan.yearlyPlan.totalAnual }} / year</small>
                    </div>
                  </span>
                </label>
              </div>

              <!-- Beneficts -->
              <div class="pb-2">
                <div
                  v-for="(benefit, index2) in plan.planBenefits"
                  :key="`${plan.id}-${index2}`"
                  class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                >
                  <v-icon
                    size="14"
                    class="me-2 mt-1"
                  >
                    {{ icons.mdiCheckboxBlankCircleOutline }}
                  </v-icon>
                  <span class="text-sm text--secondary">{{ benefit }}</span>
                </div>
              </div>
              <!--/ Beneficts -->
            </div>
          </v-col>
        </v-row>
        <!--/ Plan -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiCheckboxBlankCircleOutline, mdiOpenInNew,
  mdiBasketOutline, mdiShoppingOutline, mdiStorefrontOutline,
} from '@mdi/js'

export default {
  components: {
  },
  props: {
    planData: {
      type: Object,
      default: () => ({
        profile_code: 'purchaser',
        plan_code: 'purchaser-big-market',

        plan_price_montly: '',
        plan_annual: true,
      }),
    },
  },
  setup(props, { emit }) {
    const planDataLocal = ref(JSON.parse(JSON.stringify(props.planData)))

    // *** PLAN *** //
    // purchaser-basic, purchaser-big-market, seller-trader, seller-big-trader
    const planSelected = ref(planDataLocal.value.plan_code)
    const planList = [
      {
        id: 'purchaser-basic',
        profileCode: 'purchaser',
        title: 'Comprador Básica',
        subtitle: 'Un comienzo simple para todos',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-1.png'),
        icon: mdiBasketOutline,
        monthlyPrice: 0,
        yearlyPlan: {
          perMonth: 0,
          totalAnual: 0,
        },
        planBenefitsText: '',
        planBenefits: [
          'Marketplace',
          'Recibir ofertas sin filtros',
        ],
        popular: false,
      },
      {
        id: 'purchaser-big-market',
        profileCode: 'purchaser',
        title: 'Comprador Big Market',
        subtitle: 'Un paso más en tu negocio',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-2.png'),
        icon: mdiShoppingOutline,
        monthlyPrice: 54,
        yearlyPlan: {
          perMonth: 49,
          totalAnual: 588,
        },
        planBenefitsText: 'Todo lo del plan Básico, más',
        planBenefits: [
          'Lista de intereses',
          'Recibir ofertas de intereses',
          'Contactos',
          'Chat',
        ],
        popular: true,
      },
      {
        id: 'seller-trader',
        profileCode: 'seller',
        title: 'Vendedor Trader',
        subtitle: 'Un comienzo simple para todos',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-1.png'),
        icon: mdiStorefrontOutline,
        monthlyPrice: 32,
        yearlyPlan: {
          perMonth: 29,
          totalAnual: 348,
        },
        planBenefitsText: '',
        planBenefits: [
          'Espacio Web ',
          'Catálogo para 10 productos máximo',
          'Hacer ofertas (Con coste)',
          'Hasta 5 agentes comerciales incluidos',
          'Contactos',
          'Chat',
        ],
        popular: false,
      },
      {
        id: 'seller-big-trader',
        profileCode: 'seller',
        title: 'Vendedor Big Trader',
        subtitle: 'Un paso más en tu negocio',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/misc/pricing-tree-2.png'),
        icon: mdiStorefrontOutline,
        monthlyPrice: 54,
        yearlyPlan: {
          perMonth: 49,
          totalAnual: 588,
        },
        planBenefitsText: 'Todo lo del plan Trader, más',
        planBenefits: [
          'Catálogo ilimitado',
          'Hasta 5 ofertas gratuitas por mes y empresa',
          'Agentes comerciales ilimitados',
        ],
        popular: true,
      },
    ]

    const onChangePlan = () => {
      // eslint-disable-next-line no-use-before-define
      planDataLocal.value = readPlanData(planSelected.value)
    }

    // Period switch (annual vs monthly)
    // const periodSwitchStatus = ref(props.planAnnual ? 'annual' : 'monthly')
    const annualPeriodShow = ref(planDataLocal.value.plan_annual)

    // Se ha cambiado la frecuencia de pago
    const onTooglePeriod = () => {
      annualPeriodShow.value = !annualPeriodShow.value // Invert

      // Send data
      // eslint-disable-next-line no-use-before-define
      planDataLocal.value = readPlanData(planSelected.value)
    }

    // ***

    // Leemos toda la data del componente
    //  Otra estrategia sería mantener planDataLocal.value
    const readPlanData = planId => {
      const plan = planList.find(_p => _p.id === planId)

      // Send data
      const planConfigured = {
        profile_code: plan.profileCode,
        plan_code: planSelected.value,
        plan_price_montly: annualPeriodShow.value ? plan.yearlyPlan.perMonth : plan.monthlyPrice,
        plan_annual: annualPeriodShow.value,
      }

      return planConfigured
    }

    // Form Ref (No se usa por el momento)
    // const refFormObserver = ref(null)
    const validationForm = () => new Promise((resolve, reject) => {
      // debugger
      const planConfigured = readPlanData(planSelected.value)

      // Checking... Profile and plan obligatory
      if (planConfigured.profile_code && planConfigured.plan_code) {
        emit('input', planConfigured)
        resolve(true)
      } else {
        reject()
      }
    })
    const onChangeLanguage = () => {
      // do staff (Por ahora no es necesario porque el texto se rende)
      // Pero habrá que traducir el texto de las variables
    }

    return {
      // Form and external connections
      // refFormObserver,
      validationForm, // Available from Wizard
      onChangeLanguage, // Available from Wizard

      // Plan
      planList,
      planSelected, // radio
      onChangePlan, // Change event

      // Plan pricing switch
      annualPeriodShow,
      onTooglePeriod,

      icons: {
        mdiCheckboxBlankCircleOutline,
        mdiOpenInNew,
      },
    }
  },
}
</script>
<style lang="scss">
    // @import '@core/scss/vue/pages/page-pricing.scss';
    .list-group .list-group-item {
        background: inherit;
    }
</style>
<style lang="scss" scoped>
    $primary: #0F3852; // Fishnet blue
    $accent: #C58712; // Fishnet gold

    .custom-option-item-check {
        clip: rect(0,0,0,0);
        position: absolute;
    }
    .custom-option-item-check:checked+.custom-option-item {
        // background-color: rgba(115,103,240,.12);
        background-color: rgba($primary, .12);
        border-color: $accent;
        color: $accent; //#7367f0;
    }
    /* .custom-options-checkable .custom-option-item { */
    .custom-options-checkable {
        /* background-color: rgba(130,134,139,.06); */
        background-color: rgba(255,255,255,1);
        border: 1px solid #ebe9f1;
        /* border-radius: 0.42rem; */
        color: #82868b;
        cursor: pointer;
        width: 100%;
    }

    .pricing-value {
        font-size: 4rem!important;
        color: $accent !important;
    }
</style>
